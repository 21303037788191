
import firebase from "firebase"
import "firebase/firestore"
import "firebase/storage"
import "firebase/auth"
import "firebase/analytics";
import "firebase/app";

var firebaseConfig = {

  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,  
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID  
 
}
console.log('firebaseConfig',firebaseConfig)
const firebaseApp = firebase.initializeApp(firebaseConfig)

firebase.getCurrentUser = () => {   
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

// for using cache with unlimited size.
firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase.firestore().enablePersistence()
.catch((err) => {
    if (err.code == 'failed-precondition') {
        console.log("open one tab only")
    } else if (err.code == 'unimplemented') {
        console.log("does not support")
    }else{
        console.log(err)
    }
});

export default firebaseApp