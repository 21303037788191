import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import Cookies from 'js-cookie';
import { RouteComponentProps , useParams} from 'react-router-dom';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import Avatar from './components/avatar';
import VideoFooter from './components/video-footer';
import Pagination from './components/pagination';
import { useCanvasDimension } from './hooks/useCanvasDimension';
import { useGalleryLayout } from './hooks/useGalleryLayout';
import { usePagination } from './hooks/usePagination';
import { useActiveVideo } from './hooks/useAvtiveVideo';
import { useShare } from './hooks/useShare';
import { useLocalVolume } from './hooks/useLocalVolume';
import './video.scss';
import { isSupportWebCodecs } from '../../utils/platform';
import { isShallowEqual } from '../../utils/util';
import { useSizeCallback } from '../../hooks/useSizeCallback';
// import { useAdvancedFeatureSwitch } from './hooks/useAdvancedFeatureSwith';
import RemoteControlPanel, { RemoteControlIndication } from './components/remote-control';
import { useCameraControl } from './hooks/useCameraControl';
import { useNetworkQuality } from './hooks/useNetworkQuality';
import Chat from "../chat/chat";
import 'bootstrap/dist/css/bootstrap.css';
const VideoContainer: React.FunctionComponent<RouteComponentProps> = (props) => {
  const zmClient = useContext(ZoomContext);
  const [chatshow,setChatShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const userId = Cookies.get('id');
  const bookingId = Cookies.get('bookingid');
  const { id,bookid } = useParams<{ id: string ,bookid : string}>();
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady }
  } = useContext(ZoomMediaContext);
  const videoRef = useRef<HTMLCanvasElement | null>(null);
  const shareRef = useRef<HTMLCanvasElement | null>(null);
  const selfShareRef = useRef<(HTMLCanvasElement & HTMLVideoElement) | null>(null);
  const shareContainerRef = useRef<HTMLDivElement | null>(null);
  const [containerDimension, setContainerDimension] = useState({
    width: 0,
    height: 0
  });
  const [shareViewDimension, setShareViewDimension] = useState({
    width: 0,
    height: 0
  });
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const activeVideo = useActiveVideo(zmClient);
  const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zmClient, canvasDimension);
  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    {
      page,
      pageSize,
      totalPage,
      totalSize
    }
  );
  const { isRecieveSharing, isStartedShare, sharedContentDimension } = useShare(zmClient, mediaStream, shareRef);

  const { userVolumeList, setLocalVolume } = useLocalVolume();
  const {
    isControllingFarEnd,
    currentControlledUser,
    isInControl,
    giveUpControl,
    stopControl,
    turnDown,
    turnRight,
    turnLeft,
    turnUp,
    zoomIn,
    zoomOut,
    switchCamera
  } = useCameraControl(zmClient, mediaStream);

  // const { advancedSwitch, toggleAdjustVolume, toggleFarEndCameraControl } = useAdvancedFeatureSwitch(
  //   zmClient,
  //   mediaStream
  // );
  // const networkQuality = useNetworkQuality(zmClient);

  const isSharing = isRecieveSharing || isStartedShare;
  useEffect(() => {
    if (isSharing && shareContainerRef.current) {
      const { width, height } = sharedContentDimension;
      const { width: containerWidth, height: containerHeight } = containerDimension;
      const ratio = Math.min(containerWidth / width, containerHeight / height, 1);
      setShareViewDimension({
        width: Math.floor(width * ratio),
        height: Math.floor(height * ratio)
      });
    }
  }, [isSharing, sharedContentDimension, containerDimension]);

  const onShareContainerResize = useCallback(({ width, height }) => {
    _.throttle(() => {
      setContainerDimension({ width, height });
    }, 50)();
  }, []);
  useSizeCallback(shareContainerRef.current, onShareContainerResize);
  useEffect(() => {
    if (!isShallowEqual(shareViewDimension, sharedContentDimension)) {
      mediaStream?.updateSharingCanvasDimension(shareViewDimension.width, shareViewDimension.height);
    }
  }, [mediaStream, sharedContentDimension, shareViewDimension]);
  // const onAdvancedFeatureToggle = useCallback(
  //   (userId: number, key: string) => {
  //     if (key === 'volume') {
  //       toggleAdjustVolume(userId);
  //     } else if (key === 'farend') {
  //       if (isControllingFarEnd) {
  //         giveUpControl();
  //       } else {
  //         mediaStream?.requestFarEndCameraControl(userId);
  //       }
  //       // toggleFarEndCameraControl(userId);
  //     }
  //   },
  //   [toggleAdjustVolume, giveUpControl, mediaStream, isControllingFarEnd]
  // );
  return (
    <div>
      <div>
      {id == userId && bookid == bookingId ?
        <div className="viewport">
          <div
            className={classnames('share-container', {
              'in-sharing': isSharing
            })}
            ref={shareContainerRef}
          >
            <div
              className="share-container-viewport"
              style={{
                width: `${shareViewDimension.width}px`,
                height: `${shareViewDimension.height}px`
              }}
            >
              <canvas className={classnames('share-canvas', { hidden: isStartedShare })} ref={shareRef} />
              {isSupportWebCodecs() ? (
                <video
                  className={classnames('share-canvas', {
                    hidden: isRecieveSharing
                  })}
                  ref={selfShareRef}
                />
              ) : (
                <canvas
                  className={classnames('share-canvas', {
                    hidden: isRecieveSharing
                  })}
                  ref={selfShareRef}
                />
              )}
            </div>
          </div>
          <div
            className={classnames('video-container', {
              'in-sharing': isSharing
            })}
          >
            <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />
            <ul className="avatar-list">
              {visibleParticipants.map((user, index) => {
                if (index > videoLayout.length - 1) {
                  return null;
                }
                const dimension = videoLayout[index];
                const { width, height, x, y } = dimension;
                const { height: canvasHeight } = canvasDimension;
                return (
                  <Avatar
                    participant={user}
                    key={user.userId}
                    isActive={activeVideo === user.userId}
                    style={{
                      width: `${width}px`,
                      height: `${height}px`,
                      top: `${canvasHeight - y - height}px`,
                      left: `${x}px`,
                    }}
                  />
                );
              })}
            </ul>
          </div>
          <VideoFooter
            className="video-operations"
            sharing
            shareRef={selfShareRef}
            hovertitle = {chatshow}
            hovernotestitle = {show1}
            btnClick={()=>setChatShow(true)}
            notebtnClick={()=>setShow1(true)}
        />
          {chatshow ? 
            
            <div >
                <Chat />
            </div>
          : 
            <div style={{display:'none'}}>
              <Chat/>
            </div>
          }
          {isControllingFarEnd && (
            <RemoteControlPanel
              turnDown={turnDown}
              turnLeft={turnLeft}
              turnRight={turnRight}
              turnUp={turnUp}
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              switchCamera={switchCamera}
              controlledName={currentControlledUser.displayName}
            />
          )}
          {isInControl && <RemoteControlIndication stopCameraControl={stopControl} />}
          {totalPage > 1 && <Pagination page={page} totalPage={totalPage} setPage={setPage} inSharing={isSharing} />}
        </div>
        :
        <div></div>
      }
      </div>
    </div>
  );
};

export default VideoContainer;
