import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  MutableRefObject,
} from 'react';
import firebase from "../../../firebase"

import firebaseTimestamp from "firebase";
import { devConfig } from '../../../config/dev';
import notesicon from './notesIcon.png'
import noteshovericon from './notesHoverIcon.png'
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import Modal from 'react-bootstrap/Modal';
import { Button, Form, Modal, ModalHeader, ModalFooter, FormGroup, ModalBody, Input } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { message } from 'antd';
import ZoomContext from '../../../context/zoom-context';
import RecordingContext from '../../../context/recording-context';
import CameraButton from './camera';
import MicrophoneButton from './microphone';
import ChatButton from './chat';
import { ScreenShareButton } from './screen-share';
import ZoomMediaContext from '../../../context/media-context';
import { useUnmount, useMount } from '../../../hooks';
import { MediaDevice } from '../video-types';
import './video-footer.scss';
import { isAndroidBrowser, isSupportOffscreenCanvas } from '../../../utils/platform';
import { getPhoneCallStatusDescription, SELF_VIDEO_ID } from '../video-constants';
import { getRecordingButtons, RecordButtonProps, RecordingButton } from './recording';
import { DialoutState, RecordingStatus, MutedSource, AudioChangeAction, DialOutOption, VideoCapturingState } from '@zoom/videosdk';
import { Tooltip, Button as AntdButton } from 'antd';
import { IconFont } from "../../../component/icon-font";

const db = firebase.firestore();
interface VideoFooterProps {
  className?: string;
  shareRef?: MutableRefObject<HTMLCanvasElement | null>;
  sharing?: boolean;
  removeClass?: boolean;
  hovertitle?: boolean;
  // btnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  // setShow:(event: React.MouseEvent<HTMLButtonElement>) => void;
}


const isAudioEnable = typeof AudioWorklet === 'function';
const VideoFooter = (props: any) => {
  const { className, shareRef, sharing, btnClick, hovertitle, notebtnClick, hovernotestitle } = props;
  const [isChatOpen, setIsChatOpen] = useState(false);
  const userId = Cookies.get('id');
  const bookingId = Cookies.get('bookingid');
  const { id, bookid } = useParams<{ id: string, bookid: string }>();
  const [isStartedAudio, setIsStartedAudio] = useState(false);
  const [isStartedVideo, setIsStartedVideo] = useState(false);
  const [audio, setAudio] = useState('');
  const [isSupportPhone, setIsSupportPhone] = useState(false);
  const [phoneCountryList, setPhoneCountryList] = useState<any[]>([]);
  const [phoneCallStatus, setPhoneCallStatus] = useState<DialoutState>();
  const [isStartedScreenShare, setIsStartedScreenShare] = useState(false);
  const [isMirrored, setIsMirrored] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [activeMicrophone, setActiveMicrophone] = useState('');
  const [activeSpeaker, setActiveSpeaker] = useState('');
  const [activeCamera, setActiveCamera] = useState('');
  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>([]);
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const { mediaStream } = useContext(ZoomMediaContext);
  const recordingClient = useContext(RecordingContext);
  const [recordingStatus, setRecordingStatus] = useState<'' | RecordingStatus>(recordingClient?.getCloudRecordingStatus() || '');
  const zmClient = useContext(ZoomContext);
  const [ClassAdd, setClassAdd] = useState(false);
  const [notes, setTextArea] = useState('');
  const [notesClassAdd, setnotesClassAdd] = useState(false);
  var chatbtnClasses = classNames({
    'btn-chat-custom  ant-btn ant-btn-circle ant-btn-default ant-btn-lg ant-btn-icon-only ant-btn-background-ghost microphone-button ant-tooltip-open': true,
    'chatHoverClass': ClassAdd === true && hovertitle === true
  });

  const onChatClick = useCallback(async () => {
    setIsChatOpen(true);
  }, [isChatOpen]);

  const onChatCancelClick = useCallback(async () => {
    setIsChatOpen(false);
  }, [isChatOpen]);

  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); console.log("SDADADADAd") };
  const toggle = () => setShow(!show);
  var notesbtnClasses = classNames({
    'btn-notes-custom  ant-btn ant-btn-circle ant-btn-default ant-btn-lg ant-btn-icon-only ant-btn-background-ghost microphone-button ant-tooltip-open': true,
    'notesHoverClass': show === true
  });
  const onCameraClick = useCallback(async () => {
    if (isStartedVideo) {
      await mediaStream?.stopVideo();
      setIsStartedVideo(false);
    } else {
      if (
        isAndroidBrowser() ||
        (isSupportOffscreenCanvas() && !mediaStream?.isSupportMultipleVideos())
      ) {
        const videoElement = document.querySelector(
          `#${SELF_VIDEO_ID}`,
        ) as HTMLVideoElement;
        if (videoElement) {
          await mediaStream?.startVideo({ videoElement });
        }
      } else {
        await mediaStream?.startVideo({ hd: true });
      }

      setIsStartedVideo(true);
    }
  }, [mediaStream, isStartedVideo]);
  const onMicrophoneClick = useCallback(async () => {
    if (isStartedAudio) {
      if (isMuted) {
        await mediaStream?.unmuteAudio();
        setIsMuted(false);
      } else {
        await mediaStream?.muteAudio();
        setIsMuted(true);
      }
    } else {
      await mediaStream?.startAudio();
      setIsStartedAudio(true);
    }
  }, [mediaStream, isStartedAudio, isMuted]);
  const onMicrophoneCancelClick = useCallback(async () => {
    // if (isStartedAudio) {
    //   if (isMuted) {
    //     await mediaStream?.unmuteAudio();
    //     setIsMuted(false);
    //   } else {
    //     await mediaStream?.muteAudio();
    //     setIsMuted(true);
    //   }
    // } else {
    //   await mediaStream?.startAudio();
    // }
    setIsStartedAudio(false);
  }, [mediaStream, isStartedAudio, isMuted]);

  const onMicrophoneMenuClick = async (key: string) => {
    if (mediaStream) {
      const [type, deviceId] = key.split('|');
      if (type === 'microphone') {
        if (deviceId !== activeMicrophone) {
          await mediaStream.switchMicrophone(deviceId);
          setActiveMicrophone(mediaStream.getActiveMicrophone());
        }
      } else if (type === 'speaker') {
        if (deviceId !== activeSpeaker) {
          await mediaStream.switchSpeaker(deviceId);
          setActiveSpeaker(mediaStream.getActiveSpeaker());
        }
      } else if (type === 'leave audio') {
        if (audio === 'computer') {
          await mediaStream.stopAudio();
        } else if (audio === 'phone') {
          await mediaStream.hangup();
          setPhoneCallStatus(undefined);
        }
        setIsStartedAudio(false);
      }
    }
  };
  const onSwitchCamera = async (key: string) => {
    if (mediaStream) {
      if (activeCamera !== key) {
        await mediaStream.switchCamera(key);
        setActiveCamera(mediaStream.getActiveCamera());
      }
    }
  };
  const onMirrorVideo = async () => {
    await mediaStream?.mirrorVideo(!isMirrored);
    setIsMirrored(!isMirrored);
  };
  const onPhoneCall = async (code: string, phoneNumber: string, name: string, option: DialOutOption) => {
    await mediaStream?.inviteByPhone(code, phoneNumber, name, option);
  }
  const onPhoneCallCancel = async (code: string, phoneNumber: string, option: { callMe: boolean }) => {
    if ([DialoutState.Calling, DialoutState.Ringing, DialoutState.Accepted].includes(phoneCallStatus as any)) {
      await mediaStream?.cancelInviteByPhone(code, phoneNumber, option);
      await new Promise((resolve) => { setTimeout(() => { resolve(true) }, 3000) });
    }
    return Promise.resolve();
  }
  const onHostAudioMuted = useCallback((payload: any) => {
    const { action, source, type } = payload;
    if (action === AudioChangeAction.Join) {
      setIsStartedAudio(true);
      setAudio(type);
    } else if (action === AudioChangeAction.Leave) {
      setIsStartedAudio(false);
    } else if (action === AudioChangeAction.Muted) {
      setIsMuted(true);
      if (source === MutedSource.PassiveByMuteOne) {
        message.info('Host muted you');
      }
    } else if (action === AudioChangeAction.Unmuted) {
      setIsMuted(false);
      if (source === 'passive') {
        message.info('Host unmuted you');
      }
    }
  }, []);
  const onScreenShareClick = useCallback(async () => {
    debugger // eslint-disable-line
    // console.log('sdfsdfasdf',shareRef,isStartedScreenShare,mediaStream)
    try {
      if (!isStartedScreenShare && shareRef && shareRef.current) {
        await mediaStream?.startShareScreen(shareRef.current);
        setIsStartedScreenShare(true);
      } else if (isStartedScreenShare) {
        await mediaStream?.stopShareScreen();
        setIsStartedScreenShare(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [mediaStream, isStartedScreenShare, shareRef]);
  const onPassivelyStopShare = useCallback((reason: any) => {
    // console.log('passively stop reason:', reason);
    setIsStartedScreenShare(false);
  }, []);
  const onDeviceChange = useCallback(() => {
    if (mediaStream) {
      setMicList(mediaStream.getMicList());
      setSpeakerList(mediaStream.getSpeakerList());
      setCameraList(mediaStream.getCameraList());
      setActiveMicrophone(mediaStream.getActiveMicrophone());
      setActiveSpeaker(mediaStream.getActiveSpeaker());
      setActiveCamera(mediaStream.getActiveCamera());
    }
  }, [mediaStream]);

  const onRecordingChange = useCallback(() => {
    setRecordingStatus(recordingClient?.getCloudRecordingStatus() || '');
  }, [recordingClient]);

  const onDialOutChange = useCallback((payload: any) => {
    setPhoneCallStatus(payload.code)
  }, [])

  const onRecordingClick = async (key: string) => {
    switch (key) {
      case 'Record': {
        await recordingClient?.startCloudRecording();
        break;
      }
      case 'Resume': {
        await recordingClient?.resumeCloudRecording();
        break;
      }
      case 'Stop': {
        await recordingClient?.stopCloudRecording();
        break;
      }
      case 'Pause': {
        await recordingClient?.pauseCloudRecording();
        break;
      }
      case 'Status': {
        break;
      }
      default: {
        await recordingClient?.startCloudRecording();
      }
    }
  };
  const onVideoCaptureChange = useCallback((payload: any) => {
    if (payload.state === VideoCapturingState.Started) {
      setIsStartedVideo(true);
    } else {
      setIsStartedVideo(false);
    }
  }, [])

  useEffect(() => {
    zmClient.on('current-audio-change', onHostAudioMuted);
    zmClient.on('passively-stop-share', onPassivelyStopShare);
    zmClient.on('device-change', onDeviceChange);
    zmClient.on('recording-change', onRecordingChange);
    zmClient.on('dialout-state-change', onDialOutChange);
    zmClient.on('video-capturing-change', onVideoCaptureChange)
    return () => {
      zmClient.off('current-audio-change', onHostAudioMuted);
      zmClient.off('passively-stop-share', onPassivelyStopShare);
      zmClient.off('device-change', onDeviceChange);
      zmClient.off('recording-change', onRecordingChange)
      zmClient.off('dialout-state-change', onDialOutChange);
      zmClient.off('video-capturing-change', onVideoCaptureChange)
    };
  }, [zmClient, onHostAudioMuted, onPassivelyStopShare, onDeviceChange, onRecordingChange, onDialOutChange, onVideoCaptureChange]);

  useUnmount(() => {
    if (isStartedAudio) {
      mediaStream?.stopAudio();
    }
    if (isStartedVideo) {
      mediaStream?.stopVideo();
    }
    if (isStartedScreenShare) {
      mediaStream?.stopShareScreen();
    }
  });
  useMount(() => {
    setIsSupportPhone(!!mediaStream?.isSupportPhoneFeature());
    setPhoneCountryList(mediaStream?.getSupportCountryInfo() || []);
  });

  useEffect(() => {
    db.collection('bookings').doc(bookingId).get().then(doc => {
      const docData = doc.data();
      const meetingEndTime = new Date(docData?.endTime?.seconds * 1000).getTime();
      const meetEndWarningTime = new Date(new Date(docData?.endTime?.seconds * 1000).getTime() - 9 * 60000).getTime();
      // console.log(meetEndWarningTime,'meetEndWarningTime',meetingEndTime);
      const remainingMinutes = meetingEndTime - new Date().getTime()
      const minutes = Math.floor((remainingMinutes % (1000 * 60 * 60)) / (1000 * 60));
      const intervalId = setInterval(() => {
        // console.log('meetEndTime',meetingEndTime,new Date().getTime())
        if (meetEndWarningTime <= new Date().getTime()) {
          clearInterval(intervalId);
          Swal.fire({
            title: '',
            text: `Meeting will be finished after ${minutes == 0 ? 1 : minutes} minute.`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
          } as SweetAlertOptions).then((result) => {
            if (result.value) {
            }
          })

          const intervalFinal = setInterval(() => {
            if (meetingEndTime <= new Date().getTime()) {
              clearInterval(intervalFinal);
              try {
                const sessionhistory = db.collection('bookings').doc(bookingId).collection('sessionHistory').doc();
                db.collection('bookings').doc(bookingId).collection('sessionHistory').doc(sessionhistory.id).set({
                  id: sessionhistory.id,
                  createdAt: new Date(),
                  action: 'leave',
                  userId: userId,
                  platform: 'web',
                }).then(() => {
                  zmClient.leave();
                  Cookies.remove('id');
                  Cookies.remove('bookingid');
                  Cookies.remove('fullName');
                  Cookies.remove('pass');
                  // Modal.warning({
                  //   title: "Meeting ended",
                  //   content: "Your session is end",
                  // });
                  Swal.fire({
                    title: '',
                    text: `Your session is end`,
                    icon: 'warning',
                    showCancelButton: false,
                    showConfirmButton: false,
                  } as SweetAlertOptions).then((result) => {
                    if (result.value) {
                    }
                  })
                  setTimeout(() => {
                    window.location.replace(devConfig.redirectLink + '/dashboard')
                    // window.location.replace('http://localhost:8080/dashboard');
                  }, 2000)
                })
              } catch (e: any) {
                console.log(e, 'error');
              }
            }
          }, 1000)
        }
      }, 3000);
    })
  }, []);
  useEffect(() => {
    // console.log('sdfdsf',zmClient.getSessionInfo())
    db.collection('bookings').doc(bookingId).update({
      sessionId: zmClient.getSessionInfo().sessionId
    })
  }, [])
  function handleSubmitInDatabase() {
    debugger // eslint-disable-line
    console.log('handal in datababse', notes, bookingId, show)
    if (notes) {
      setShow(false);
      db.collection('bookings').doc(bookingId).update({
        notes: firebaseTimestamp.firestore.FieldValue.arrayUnion(
          notes
        )
      }).then(() => {
        setTextArea('');
        message.warn("Note added successfully");
      })
    } else {
      setShow(false);
      handleClose()
      setTextArea('');
      message.warn("Note field is required");
    }
  }
  function leavsesessionHandler() {
    //  debugger // eslint-disable-line
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to leave session?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, leave it!'
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        console.log("bookingId", bookingId, "id", id);

        const sessionhistory = db.collection('bookings').doc(bookingId).collection('sessionHistory').doc();
        db.collection('bookings').doc(bookingId).collection('sessionHistory').doc(sessionhistory.id).set({
          id: sessionhistory.id,
          createdAt: new Date(),
          action: 'leave',
          userId: userId,
          platform: 'web',
        }).then(() => {
          zmClient.leave();
          window.location.replace(devConfig.redirectLink + '/dashboard')
          Cookies.remove('id');
          Cookies.remove('bookingid');
          Cookies.remove('fullName');
          Cookies.remove('pass');
        })
      }
    });
  }
  const recordingButtons: RecordButtonProps[] = getRecordingButtons(recordingStatus, zmClient.isHost());
  return (
    <div className={classNames('video-footer', className)}>
      <div>
        <div onClick={() => {
          setIsChatOpen(false)
          leavsesessionHandler()
        }
        }>
          <button style={{ backgroundColor: '#000', color: 'red', cursor: 'pointer', position: 'fixed', top: '20px', right: '25px', border: '1px solid red', padding: '5px 15px 8px', borderRadius: '5px' }}>Leave</button>

        </div>
      </div>
      <div>
        <Tooltip
          title={show ? 'Note add' : 'Note add'}
        >
          <AntdButton
            className={notesbtnClasses} style={{ marginRight: '30px' }}
            size="large"
            icon={<>
              <img src={notesicon} style={{ height: '30px', width: '30px' }} className="notesdefault" />
              <img src={noteshovericon} style={{ height: '30px', width: '30px' }} className="noteshover" />
            </>}
            ghost
            shape="circle"
            onClick={() => {
              setShow(true)
              console.log("hello")
              setnotesClassAdd(true)
            }
            }
          />
          {/* <button onClick={() => {
              setShow(true)
              setnotesClassAdd(true)
            }} className={notesbtnClasses} style={{ marginRight: '30px' }}>
              <img src={notesicon} style={{ height: '30px', width: '30px' }} className="notesdefault" />
              <img src={noteshovericon} style={{ height: '30px', width: '30px' }} className="noteshover" />

            </button> */}
        </Tooltip>
        {/* <Modal show={show} onHide={handleClose}>
          <ModalHeader closeButton>
            Notes
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <FormControl as="textarea" rows={3} autoFocus={true} onChange={(e) => {setTextArea(e.target.value)}} onKeyPress={(e) => ( e.key === 'Enter' || e.key === 'NumpadEnter') && handleSubmitInDatabase()} />
              <FormGroup row>
                <Label
                  for="exampleText"
                  sm={2}
                >
                  Text Area
                </Label>
                <Col sm={10}>
                  <Input
                    id="exampleText"
                    name="text"
                    type="textarea"
                    onChange={(e) => {setTextArea(e.target.value)}} onKeyPress={(e) => ( e.key === 'Enter' || e.key === 'NumpadEnter') && handleSubmitInDatabase()}
                  />
                </Col>
            </FormGroup>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={handleClose} >
              Close
            </Button>
            <Button variant="primary" onClick={() => {
              handleSubmitInDatabase()
            }}>
              Submit
            </Button>
          </ModalFooter>
        </Modal> */}
        <Modal isOpen={show}  toggle={toggle}>
          <ModalHeader >Notes</ModalHeader>
          <ModalBody>
            <Input
              type="textarea"
              rows={5}
              onChange={(e) => { setTextArea(e.target.value) }} onKeyPress={(e) => (e.key === 'Enter' || e.key === 'NumpadEnter') && handleSubmitInDatabase()}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => {
              handleSubmitInDatabase()
            }}>
              Submit
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <ChatButton
        isChatOpen={isChatOpen}
        isMuted={isMuted}
        isSupportPhone={isSupportPhone}
        audio={audio}
        phoneCountryList={phoneCountryList}
        onPhoneCallClick={onPhoneCall}
        onPhoneCallCancel={onPhoneCallCancel}
        phoneCallStatus={getPhoneCallStatusDescription(phoneCallStatus)}
        onChatClick={onChatClick}
        onChatCancelClick={onChatCancelClick}
        microphoneList={micList}
        speakerList={speakerList}
        activeMicrophone={activeMicrophone}
        activeSpeaker={activeSpeaker}
      />
      {isAudioEnable && (
        <MicrophoneButton
          isStartedAudio={isStartedAudio}
          isMuted={isMuted}
          isSupportPhone={isSupportPhone}
          audio={audio}
          phoneCountryList={phoneCountryList}
          onPhoneCallClick={onPhoneCall}
          onPhoneCallCancel={onPhoneCallCancel}
          phoneCallStatus={getPhoneCallStatusDescription(phoneCallStatus)}
          onMicrophoneClick={onMicrophoneClick}
          onMicrophoneMenuClick={onMicrophoneMenuClick}
          microphoneList={micList}
          speakerList={speakerList}
          activeMicrophone={activeMicrophone}
          activeSpeaker={activeSpeaker}
        />
      )}
      <CameraButton
        isStartedVideo={isStartedVideo}
        onCameraClick={onCameraClick}
        onSwitchCamera={onSwitchCamera}
        onMirrorVideo={onMirrorVideo}
        cameraList={cameraList}
        activeCamera={activeCamera}
        isMirrored={isMirrored}
      />
      {sharing && (
        <ScreenShareButton
          isStartedScreenShare={isStartedScreenShare}
          onScreenShareClick={onScreenShareClick}
        />
      )}
      {/* {recordingButtons.map((button: RecordButtonProps) => {
        return <RecordingButton key={button.text} onClick={()=>{onRecordingClick(button.text)}} {...button} />
      })} */}
      {/* {(zmClient.isManager() || zmClient.isHost())&& (
        <ScreenShareLockButton
        isLockedScreenShare={isLockedScreenShare}
        onScreenShareLockClick={()=>{
          mediaStream?.lockShare(!isLockedScreenShare);
          setIsLockedScreenShare(!isLockedScreenShare);
        }}
      />
      )} */}
    </div>
  );
};
export default VideoFooter;
