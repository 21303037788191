import { getExploreName } from '../utils/platform';
import Cookies from 'js-cookie';
export const devConfig = {
  sdkKey:'zp781UJfy1PEX3qXtZY3QK7ZqK0HSRt9f2HP',
  sdkSecret: 'STfxCl8jb6cvpFrTKzQ02IeCOTcWDDBYfN44',
  topic: Cookies.get('pass'),
  name: Cookies.get('fullName'),
  password: Cookies.get('pass'),
  signature: '',
  redirectLink: 'https://app.tutorline.academy'
};
